.h1,
h1 {
  @apply text-3xl font-medium text-gray-700;
}

.h2,
h2 {
  @apply text-2xl font-medium text-gray-700;
}

.h3,
h3 {
  @apply text-xl font-medium text-gray-700;
}

.h4,
h4 {
  @apply text-lg font-medium text-gray-700;
}

.h5,
h5 {
  @apply text-base font-medium text-gray-700;
}

.h6,
h6 {
  @apply text-sm font-medium text-gray-700;
}

/* PANELS */

.sidebar-content-container {
  @apply flex flex-col justify-between h-full w-full;

  .form-wrapper {
    @apply flex flex-col gap-y-5 pb-20 overflow-y-scroll;
  }

  .manage-footer {
    @apply flex flex-col gap-y-3 pt-3 w-full;

    .double-button {
      @apply flex flex-row w-full gap-x-3;
    }
  }
}

.p-invalid {
  border-color: red;
}

.data-section-row {
  @apply flex flex-row gap-5 flex-wrap;
}

.p-dataview-emptymessage {
  @apply italic text-slate-400 font-light;
}

// Autocomplete
.p-autocomplete {
  input {
    width: 100%;
  }
}

.p-autocomplete-item {
  @apply text-sm;
}

.p-autocomplete .p-autocomplete-multiple-container {
  width: 100%;
}

// Rich Text Editor
.ql-editor {
  font-size: 16px;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.ql-toolbar {
  @apply rounded-tl-lg rounded-tr-lg;
}
.ql-container {
  @apply rounded-bl-lg rounded-br-lg;
}

// Dropdown
.p-multiselect-item > span {
  @apply w-full;
}
